
import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import moment from 'moment-timezone';
import { v1TaglessService } from '../../../services/Services';
import { ExpandableTable } from '../../../components/Table/ExpandableTable';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { createVideoURI, generateMinuteVideoMp4FileNameFromUrl } from './utils';
import { AppConfig } from '../../../appConfig';
import { useDownloadFile } from '../../../utils/useDownloadFile';
import { EMPTY_DATA_STRING } from './DailyReport';

const MinuteReport = ({ selectedDate, site, devices, setErrorState, activeTab }) => {

	const [hourlyCountData, setHourlyCountData] = useState([]);
    const [processedData, setProcessedData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const config = useContext(AppConfig);
    const { downloadFile , downloading, downloadUrl } = useDownloadFile();

	const fetchHourlyData = useCallback(async () => {
        setIsLoading(true);
        try {
            const startDate = moment(selectedDate).startOf('day');
            const endDate = moment(selectedDate).endOf('day');

            const hourlyData = await Promise.all(devices.map(async (device) => {
                try {
                    const counts = await v1TaglessService.getDetections(device.id, {
                        startTimestamp: startDate.toISOString(),
                        endTimestamp: endDate.toISOString(),
                        interval: "1h",
                    });
                    return {
                        device: device,
                        countData: counts.counts
                    };
                } catch (error) {
                    setErrorState({
                        hasError: true,
                        message: `unable to load detections data for Top of Ladder`
                    });
                    return null;
                }
            }));

            const filteredHourlyData = hourlyData.filter(Boolean);
            if(filteredHourlyData?.count?.length===0){
                console.log("No data")
            }
            setHourlyCountData(filteredHourlyData);
        } catch (error) {
            setErrorState({
                hasError: true,
                message: `Error fetching hourly data: ${error}`
            });
            setHourlyCountData([]);
        } 
    }, [selectedDate, devices, setErrorState]);

	const processApiData = useCallback((deviceCountData, timeFormat = 'HH:mma', isSubRow = false) => {
        let combinedData = new Map();
        let type = "hour";
        let url;

        deviceCountData.forEach((deviceData) => {
            if (!deviceData || !deviceData.countData) return;
            
            deviceData.countData.forEach((count) => {
                if (!count.startTimestamp || !count.endTimestamp) return;
                
                const startTime = moment(count.startTimestamp).tz('America/Halifax').format(timeFormat);
				let timerange;

                if (isSubRow) {
                    timerange = startTime; 
                    type = "minute"
                    url = createVideoURI(count.startTimestamp, deviceData.device, site, config)
                } else {
                    const endTime = moment(count.endTimestamp).tz('America/Halifax').format(timeFormat);
                    timerange = `${startTime} - ${endTime}`;
                }

                const timeRangeData = combinedData.get(timerange) || {};
                combinedData.set(timerange, {
                    ...timeRangeData,
                    [deviceData.device.name]: {
                        upstream: count.upstreamCount || 0,
                        downstream: count.downstreamCount || 0,
                        type: type,
                        url: url
                    }
                });
            });
        });

        let finalData = Array.from(combinedData, ([key, value]) => ({
            id: key,
            timerange: key,
            ...value
        }));
        if (isSubRow) {
            finalData.sort((a, b) => moment(a.timerange, 'mm').diff(moment(b.timerange, 'mm')));
          }
        return finalData;
    }, [site]);

	const columns = useMemo(() => {
        const baseColumns = [
            {
                header: moment(selectedDate).format('MMMM D, YYYY'),
                columns: [
                    {
                        accessorKey: "timerange",
                        header: 'Time Range',
                        size: 25,
                        muiTableBodyCellProps: {
                            sx: {
                                textAlign: "center"
                            }
                        },
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                    }
                ]
            }
        ];

        if (devices){

            const deviceColumns = devices.map(device => ({
                header: device.displayName,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                columns: [
                    {
                        header: "Upstream",
                        size: 25,
                        accessorFn: (row) => row[device.name]?.upstream !== undefined && row[device.name]?.upstream !== null ? row[device.name]?.upstream : EMPTY_DATA_STRING,
                        id: `${device.name}-upstream`,
                        muiTableBodyCellProps: {
                            sx: {
                                textAlign: "center"
                            }
                        },
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                    },
                    {
                        header: "Downstream",
                        size: 25,
                        accessorFn: (row) => row[device.name]?.downstream !== undefined && row[device.name]?.downstream !== null ? row[device.name]?.downstream : EMPTY_DATA_STRING,
                        id: `${device.name}-downstream`,
                        muiTableBodyCellProps: {
                            sx: {
                                textAlign: "center"
                            }
                        },
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                    },
                    {
                        header: "",
                        size: 25,
                        accessorKey: `${device.name}-vid`,
                        Cell: ({ row }) => (
                            row.original[device.name]?.type == "minute" ?
                            <Tooltip title={`Download ${generateMinuteVideoMp4FileNameFromUrl(site.name, device.name, row.original[device.name]?.url)}`} >
                                <span>
                                    <IconButton
                                        size='small'
                                        sx={{
                                            background: "#4eafb2",
                                            '&:hover': {
                                                backgroundColor: '#043c4a',
                                                boxShadow: 'none',
                                            }
                                        }}
                                        component="a"
                                        disabled={downloading && downloadUrl === row.original[device.name]?.url}
                                        onClick={async() => {
                                            downloadFile(generateMinuteVideoMp4FileNameFromUrl(site.name, device.name, row.original[device.name]?.url), row.original[device.name]?.url)
                                        }}
                                    >
                                        {downloading && downloadUrl === row.original[device.name]?.url ? <CircularProgress size={28}/> : <DownloadIcon/>   }                              
                                    </IconButton> 
                                </span>
                            </Tooltip> : null
                        ),
                        muiTableBodyCellProps: {
                            sx: {
                                textAlign: "center"
                            }
                        },
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                    },
                    
                ]
            }));


            return [...baseColumns, ...deviceColumns];
        }
		
	}, [devices, selectedDate, downloading, downloadUrl]);

	const fetchSubRows = useCallback(async (parentRow) => {
		try {
			const [startTime, endTime] = parentRow.timerange.split(' - ');
			const startDate = moment.tz(selectedDate, 'America/Halifax').format('YYYY-MM-DD');
			const startTimestamp = moment.tz(`${startDate} ${startTime}`, 'YYYY-MM-DD HH:mm', 'America/Halifax').toISOString();
			const endTimestamp = moment.tz(`${startDate} ${endTime}`, 'YYYY-MM-DD HH:mm', 'America/Halifax').toISOString();

			const subRowsData = await Promise.all(devices.map(async (device) => {
				const minuteCounts = await v1TaglessService.getDetections(device.id, {
					startTimestamp,
					endTimestamp,
					interval: "1m",
				});
				return {
					device,
					countData: minuteCounts.counts
				};
			}));

			const processedSubRows = processApiData(subRowsData, 'mm', true);

			return processedSubRows.map(subRow => ({
				...subRow,
				parentId: parentRow.id
			}));
		} catch (error) {
            setErrorState({
                hasError: true,
                message: `unable to load minute-level data`
            });
			return [];
		}
	}, [devices, selectedDate, processApiData, setErrorState]);

	useEffect(() => {
        fetchHourlyData();
    }, [selectedDate, fetchHourlyData]);

    useEffect(() => {
        if (hourlyCountData.length > 0) {
            const processed = processApiData(hourlyCountData);
            setProcessedData(processed);
        } else {
            setProcessedData([]);
        }
        setIsLoading(false);
    }, [hourlyCountData, processApiData]);

    useEffect(() => {
        if (activeTab === 1 && devices?.length > 0) {
            fetchHourlyData();
        }
    }, [activeTab, devices, fetchHourlyData, selectedDate]);
	return (
		<div>
			{isLoading ? (
                <p>Loading...</p>
            ) : (
                <ExpandableTable
                    columns={columns}
                    data={processedData}
                    fetchSubRows={fetchSubRows}
                />
            )}
        </div>
    );
};


export default MinuteReport;


