import React, { useState } from "react";
import { InputAdornment, InputLabel, IconButton, Input, FormControl, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Container from "@mui/material/Container";
import Form from "react-bootstrap/Form";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import './ChangePassword.css'
import { HeaderPrimary } from "../../components/HeaderText/HeaderText.style";
import PasswordRequirementsError from "../../components/ErrorMessages/PasswordErrors/PasswordRequirementsMessage";
import PasswordsNotMatchedError from "../../components/ErrorMessages/PasswordErrors/PasswordNotMatchedMessage";
import { authService } from "../../services/Services";


const ChangePassword = ({ setErrorState }) => {
    const [values, setValues] = React.useState({
        currentPassword: { show: false, password: '', errorMsg: false },
        newPassword: { show: false, password: '', errorMsg: false },
        confirmPassword: { show: false, password: '', errorMsg: false }
    });

    const [passwordMatchErr, setpasswordMatchErr] = useState(false)

    const handleClickShowPassword = (event, props) => {
        event.preventDefault();
        event.stopPropagation();
        var input = values[props]
        setValues({ ...values, [props]: { show: !input.show, password: input.password } });
    }

    const handlePasswordChange = (props, value) => {
        var input = values[props]
        var err = false;
        var matched = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value)
        if (!matched) {
            err = true
        }
        
        // If requirements are not matched then show the error
        let updatedValues = {
            ...values, [props]: {
                show: input.show, password: value, errorMsg: err
            }
        }

        setValues(updatedValues)

        if (updatedValues.confirmPassword.password.length > 0 && (updatedValues.confirmPassword.password != updatedValues.newPassword.password)) {
            setpasswordMatchErr(true)
        } else {
            setpasswordMatchErr(false)
        }
    }

    const validateForm = () => {
        if (values.newPassword.password.length > 0 || values.confirmPassword.password.length > 0) {
            return (values.newPassword.password === values.confirmPassword.password)
        }
    }

    return (
        <Container sx={{ mt: '5%' }}>
            <Form className="ChangePasswordForm" >
                <HeaderPrimary>
                    Change Password
                </HeaderPrimary>
                <div className="InputContainer">
                    <FormControl sx={{ width: '25ch', mt: '5%' }} variant="outlined" className="InputControl">
                        <InputLabel htmlFor="current-password">Old Password</InputLabel>
                        <OutlinedInput
                            id="current-password"
                            type={values.currentPassword.show ? "text" : "password"}
                            label="Old Password"
                            onChange={({ target }) => handlePasswordChange("currentPassword", target.value)}
                            value={values.currentPassword.password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onMouseDown={(event) => handleClickShowPassword(event, "currentPassword")}
                                        edge="end"
                                    >
                                        {values.currentPassword.show ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                <div className="InputContainer">
                    <FormControl sx={{ width: '25ch', mt: '5%' }} variant="outlined" className="InputControl">
                        <InputLabel htmlFor="new-password">New Password</InputLabel>
                        <OutlinedInput
                            id="new-password"
                            type={values.newPassword.show ? "text" : "password"}
                            label="New Password"
                            onChange={({ target }) => handlePasswordChange("newPassword", target.value)}
                            value={values.newPassword.password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onMouseDown={(event) => handleClickShowPassword(event, "newPassword")}
                                        edge="end"
                                    >
                                        {values.newPassword.show ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {
                        values.newPassword.errorMsg ? <PasswordRequirementsError /> : null
                    }
                </div>
                <div className="InputContainer">
                    <FormControl sx={{ width: '25ch', mt: '5%' }} variant="outlined" className="InputControl">
                        <InputLabel htmlFor="new-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            id="confirm-password"
                            type={values.confirmPassword.show ? "text" : "password"}
                            label="Confirm Password"
                            onChange={({ target }) => handlePasswordChange("confirmPassword", target.value)}
                            value={values.confirmPassword.password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onMouseDown={(event) => handleClickShowPassword(event, "confirmPassword")}
                                        edge="end"
                                    >
                                        {values.confirmPassword.show ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {
                        values.confirmPassword.errorMsg ? <PasswordRequirementsError /> : passwordMatchErr ? <PasswordsNotMatchedError/> : <></>
                    }

                </div>
                <br />
                <Box
                    display="flex"
                    justifyContent="center"
                >
                    <Button variant="contained"
                        sx={{
                            bgcolor: "#043c4a",
                            height: "50px",
                            width: "100px",
                            color: "#ffffff",
                            ':hover': {
                                bgcolor: "#043c4a"
                            },
                            mt: '5%'
                        }}
                        disabled={!validateForm()}
                        type="button"
                        className="PrimaryBtn"
                        onClick={
                            async () => {
                                await authService.changePassword(values.currentPassword.password, values.newPassword.password).catch((error) => {
                                    setErrorState({
                                        hasError: true,
                                        message: error
                                    })
                                })
                            }
                        }
                    >
                        Submit
                    </Button >
                </Box>
            </Form>
        </Container>
    );
}

export default ChangePassword