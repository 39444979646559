import { Box, Typography, Toolbar, Button, Tooltip } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import moment from 'moment-timezone';
import { deviceIsValid } from '../../../utils/Validation'
import { AppConfig } from '../../../appConfig';
import { DynamicTable } from '../../../components/Table/DynamicTable';
import Graph from "../../../components/Graphs/Graph";
import { v1TaglessService } from '../../../services/Services';
import { convertToCSV, compareDateHour, getUniquePipelines } from './utils';
import { CSVLink } from "react-csv";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const SummaryReport = ({ devices, setErrorState, site }) => {

    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [csvData, setCSVData] = useState("")
    const [plotSeries, setPlotSeries] = useState([]);
    const [totalCountsSeries, setTotalCountsSeries] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const { seasonStart } = useContext(AppConfig)

    var lineChartOptions = {
        chart: {
            type: "line",
            animations: {
                enabled: false
            },
            toolbar: {
                tools: {
                    reset: false
                }
            }
        },
        stroke: {
            width: 2,
            curve: "smooth"
        },
        noData: {
            text: "Loading...",
            align: "center"
        },
        markers: {
            size: 0,
            strokeWidth: 0,
            fillOpacity: 0,
            strokeOpacity: 0,
            hover: {
                size: 3
            }
        },
        tooltip: {
            x: {
                formatter: function (val) {
                    return moment(val).format("DD/MM/YYYY HH:mm:ss")
                }
            },
            y: {
                formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                    const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];
                    if (!dataPoint) {
                        return `Total: ${value}`;
                    }
                    const { upstream, downstream } = dataPoint;
                    return `Upstream: ${upstream}\nDownstream: ${downstream}\nTotal: ${value}`;
                }
            }
        },

        xaxis: {
            type: 'datetime',
            title: {
                text: "Timestamp",
                style: {
                    color: "#000000",
                    fontSize: "15px"
                }
            },
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: "MMM 'yy",
                    day: 'dd MMM',
                    hour: 'HH:mm',
                },
                datetimeUTC: false
            },
            padding: {
                top: 0,
                right: 50,
                bottom: 0,
                left: 50
            },
        }
    };

    const cumulativeGraphOptions = {
        ...lineChartOptions,
        yaxis: {
            labels: {
                style: {
                    colour: "#000000",
                    fontSize: "15px"
                },
            },
            title: {
                text: "Cumulative Counts",
                style: {
                    color: "#000000",
                    fontSize: "15px"
                }
            }
        }
    }

    const hourlyGraphOptions = {
        ...lineChartOptions,
        yaxis: {
            labels: {
                style: {
                    colour: "#000000",
                    fontSize: "15px"
                },
            },
            title: {
                text: "Hourly Counts",
                style: {
                    color: "#000000",
                    fontSize: "15px"
                }
            }
        }
    }

    const fetchSummaryTabData = async (seasonStartDate, endDate) => {
        let row = {};
        let primaryCols = [];
        let graphSeries = []
        let totalCountsSeries = [];
        const csvObjs = new Map();
        const csvHeaders = new Set();
        csvHeaders.add("hour")

        setPlotSeries([]);
        setTotalCountsSeries([]);
        setLoading(true)

        await Promise.all(devices.map(async (device) => {
            let fishCountsByHour = await v1TaglessService.getDetections(device.id, {
                startTimestamp: seasonStartDate.toISOString(),
                endTimestamp: endDate.toISOString(),
                interval: "1h"
            }).catch((error) => {
                setErrorState({
                    hasError: true,
                    message: `unable to load detections data for ${device.displayName}`
                })
            })

            if (fishCountsByHour) {
                
                let pipelines = getUniquePipelines(fishCountsByHour.counts);
                let pipelineMetrics = {};

                await Promise.all(
                    pipelines.map(async(pipeline) => {
                        let pm = await v1TaglessService.getPipelineMetrics(pipeline)
                        // pipelineMetrics represents a map where the key is a pipelineID and the value is a list of metrics.
                        // pipelineMetrics can be used to map the pipelineIDs from the detections to get their metric values.
                        pipelineMetrics[pipeline] = pm["data"]? pm["data"]["metrics"] : []
                        return pm
                    }
                ));
                
                const upstreamKey = `${device.displayName}-upstream`
                const downstreamKey = `${device.displayName}-downstream`

                csvHeaders.add(upstreamKey)
                csvHeaders.add(downstreamKey)

                let deviceDataByHour = {
                    device: device,
                    countData: fishCountsByHour.counts,
                    dayImagePath: null,
                    validated: !deviceIsValid(device.accurateAsOf, moment(seasonStartDate))
                };

                let maxCountBucket = { "imagePath": null };
                if (deviceDataByHour.countData.length > 0) {
                    maxCountBucket = deviceDataByHour.countData.reduce((prev, curr) => {
                        return (prev.total > curr.total) ? prev : curr
                    })
                }

                deviceDataByHour.dayImagePath = maxCountBucket.imagePath

                let primaryCol = {
                    Header: device.displayName,
                    image: maxCountBucket.imagePath,
                    columns: [],
                    ordinal: parseInt(device.displayName.replace(/[^\d]/g, ''))
                }

                if (device.type === "CAMERA") {
                    primaryCol.columns.push({
                        Header: 'Upstream',
                        accessor: `${device.displayName}-upstream`
                    })
                    primaryCol.columns.push({
                        Header: 'Downstream',
                        accessor: `${device.displayName}-downstream`
                    })
                    primaryCol.columns.push({
                        Header: 'Total',
                        accessor: `${device.displayName}-total`
                    })

                    let cumulativeData = deviceDataByHour.countData.reduce((agg, next) => {
                        return {
                            "upstream": agg["upstream"] + next["upstreamCount"],
                            "downstream": agg["downstream"] + next["downstreamCount"],
                            "total": agg["total"] + next["upstreamCount"] - next["downstreamCount"]
                        }
                    }, {
                        "upstream": 0,
                        "downstream": 0,
                        "total": 0
                    })

                    row[`${device.displayName}-upstream`] = { value: cumulativeData.upstream, shouldGreyOut: deviceDataByHour.validated }
                    row[`${device.displayName}-downstream`] = { value: cumulativeData.downstream, shouldGreyOut: deviceDataByHour.validated }
                    row[`${device.displayName}-total`] = { value: cumulativeData.total, shouldGreyOut: deviceDataByHour.validated }

                    primaryCols.push(primaryCol)
                }

                // build CSV data
                for (const row in deviceDataByHour.countData) {
                    const time = moment(deviceDataByHour.countData[row]["startTimestamp"], "YYYY-MM-DDTHH:mm:ss.SSSZZ").local()
                    const key = time.format("YYYY-MM-DD HH")
                    const date = time.format("YYYY-MM-DD")
                    const hour = time.format("HH")
                    const newColumns = {
                        [upstreamKey]: { value: fishCountsByHour.counts[row]["upstreamCount"] },
                        [downstreamKey]: { value: fishCountsByHour.counts[row]["downstreamCount"] },
                    }
                    if (!csvObjs.has(key)) {
                        csvObjs.set(key, {
                            date: {
                                value: date
                            },
                            hour: {
                                value: hour
                            }
                        })
                    }

                    const oldColumns = csvObjs.get(key)
                    csvObjs.set(key, { ...oldColumns, ...newColumns })
                }

                let dateWiseCountsCumulativeUpstream = [];
                let dateWiseCountsCumulativeDownstream = [];
                let dateWiseCountsCumulativeTotal = [];
                let dateWiseCountsUpstream = [];
                let dateWiseCountsDownstream = [];
                let dateWiseCountsTotal = [];

                deviceDataByHour.countData.forEach((obj) => {
                    dateWiseCountsCumulativeUpstream.push([new Date(obj.startTimestamp), obj.cumulativeUpstream]);
                    dateWiseCountsCumulativeDownstream.push([new Date(obj.startTimestamp), obj.cumulativeDownstream]);
                    dateWiseCountsCumulativeTotal.push([new Date(obj.startTimestamp), obj.cumulativeTotal]);
                    dateWiseCountsUpstream.push([new Date(obj.startTimestamp), obj.upstreamCount]);
                    dateWiseCountsDownstream.push([new Date(obj.startTimestamp), obj.downstreamCount]);
                    dateWiseCountsTotal.push([new Date(obj.startTimestamp), obj.total]);
                })

                graphSeries.push({
                    name: deviceDataByHour.device.displayName,
                    data: dateWiseCountsCumulativeTotal.map((item, index) => ({
                        x: item[0],
                        y: item[1],
                        upstream: dateWiseCountsCumulativeUpstream[index][1],
                        downstream: dateWiseCountsCumulativeDownstream[index][1],
                    })),
                });

                totalCountsSeries.push({
                    name: deviceDataByHour.device.displayName,
                    data: dateWiseCountsTotal.map((item, index) => ({
                        x: item[0],
                        y: item[1],
                        upstream: dateWiseCountsUpstream[index][1],
                        downstream: dateWiseCountsDownstream[index][1],
                    })),
                });
            }
        }));
        setColumns(primaryCols.sort((a, b) => a.ordinal - b.ordinal));
        setLoading(false);
        setPlotSeries(graphSeries);
        setTotalCountsSeries(totalCountsSeries);
        setData([row]);

        const csvRows = []

        for (const ts of csvObjs.keys()) {
            const data = csvObjs.get(ts)
            for (const key of csvHeaders) {
                if (!(key in data)) {
                    data[key] = { value: null };
                }
            }
            csvRows.push(data)
        }

        csvRows.sort(compareDateHour)
        setCSVData(convertToCSV(csvRows))
    }

    useEffect(() => {
        let seasonStartDate = moment(seasonStart).tz(moment.tz.guess()).startOf("day");
        let endDate = moment().tz(moment.tz.guess()).endOf("day");
        setLoading(true)
        if (!devices) {
            setErrorState({
                hasError: true,
                message: `unable to load detections data for ${site.displayName}`
            })
            return;
        }
        fetchSummaryTabData(seasonStartDate, endDate)

    }, [devices])

    return (
        <>
            {columns.length > 0 && data.length > 0 && !isLoading ? (
                <Box>
                    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
                        <Typography
                            sx={{ flex: '1 1 100%', color: "#043c4a" }}
                            variant="h4"
                            id="tableTitle"
                            component="div">
                            Season Summary
                        </Typography>
                        <CSVLink
                            data={csvData}
                            filename={`${site.name}_season_report.csv`}
                            target="_blank"
                            onClick={() => {
                                return !(csvData === undefined || csvData.length === 0)
                            }}
                        >
                            <Tooltip title='Export Season Summary (CSV)'>    
                                <span>
                                    <Button
                                        sx={{
                                            background: "#4eafb2",
                                            '&:hover': {
                                                backgroundColor: '#043c4a',
                                                boxShadow: 'none',
                                            }
                                        }}
                                        startIcon={<FileDownloadIcon />}
                                        disabled={csvData === undefined || csvData.length === 0}
                                        variant="contained"
                                    >
                                        Export
                                    </Button>
                                </span>
                            </Tooltip>
                        </CSVLink>
                    </Toolbar>
                    <DynamicTable columns={columns} data={data} />
                </Box>
            ) : (
                <div>Loading summary report...</div>
            )}
            <br />
            <Box className="FishcountsGraph">
                <Typography variant="h4" sx={{ color: "#043C4A" }} component="div">Cumulative Counts</Typography>
                <Graph options={cumulativeGraphOptions} series={plotSeries}></Graph>
            </Box>
            <br />
            <Box className="FishcountsGraph">
                <Typography variant="h4" sx={{ color: "#043C4A" }} component="div">Hourly Counts</Typography>
                <Graph options={hourlyGraphOptions} series={totalCountsSeries}></Graph>
            </Box>
        </>
    )
}

export default SummaryReport