import { createTheme } from '@mui/material';

export const theme = createTheme({
    typography: {
      fontFamily: 'Avenir',
      fontSize: 16,
      h4:{
        fontSize: 20,
        fontWeight: 500
      },
      subtitle1:{
        fontSize: 14,
      }
    },
    overrides: {
        CssBaseline: {
          '@global': {
            '@font-face': ['Avenir']
          },
        },
      },
  })