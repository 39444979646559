import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableContainer
} from "@mui/material";
import { TableRowStyled } from './TableStyled';
import { useTable } from 'react-table';

export const DynamicTable = ({ columns, data }) => {
    const { getTableProps, headerGroups, rows, prepareRow } = useTable({ columns: columns, data });

    const shouldGreyOutCell = (cellData) => {
        if (cellData && cellData.hasOwnProperty('shouldGreyOut')) {
            return cellData.shouldGreyOut
        }

        return false
    }

    return (
        <TableContainer component={Paper} sx={{ border: "1px solid #043c4a33", width: "100%" }}>
            <Table {...getTableProps()}>
                <TableHead sx={{textAlign: "center"}}>
                    {headerGroups.map((headerGroup) => (
                        <React.Fragment key={headerGroup.getHeaderGroupProps().key}>
                            <TableRowStyled {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <TableCell
                                        {...column.getHeaderProps()}
                                        colSpan={column.columns && column.columns.length}
                                        key={column.getHeaderProps().key}
                                        sx={{textAlign: "center"}}
                                    >
                                        <span>{column.render('Header')}</span>
                                    </TableCell>
                                ))}
                            </TableRowStyled>
                        </React.Fragment>
                    ))}
                </TableHead>
                <TableBody>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <TableRowStyled {...row.getRowProps()} key={row.getRowProps().key}>
                                {row.cells.map((cell) => (
                                    <TableCell
                                        {...cell.getCellProps()}
                                        key={cell.getCellProps().key}
                                        sx={{textAlign: "center"}}
                                    >
                                        <span
                                            style={{
                                                color: shouldGreyOutCell(cell.value) ? '#CCCCCC' : 'inherit',
                                            }}
                                        >
                                            {typeof cell.value === 'object' ? cell.value.value : cell.render('Cell')}
                                        </span>
                                    </TableCell>
                                ))}
                            </TableRowStyled>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
