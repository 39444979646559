import { Typography, Button, Toolbar, Tooltip } from '@mui/material';
import { CSVLink } from "react-csv";
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import moment from 'moment-timezone';
import { v1TaglessService } from '../../../services/Services';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { deviceIsValid } from '../../../utils/Validation'
import { useState, useEffect, useContext } from "react"
import { DynamicTable } from '../../../components/Table/DynamicTable';
import { AppConfig } from '../../../appConfig';
import { convertToCSV, arrayBufferToBase64, getUniquePipelines } from './utils';
import ImageGrid from '../../../components/Grid/ImageGrid';
import MinuteReport from './MinuteReport';

export const EMPTY_DATA_STRING = "-"

const DailyReport = ({ setErrorState, site, devices, activeTab }) => {
    const config = useContext(AppConfig);
    const [dailyReportData, setDailyReportData] = useState([]);
    const [dailyReportColumns, setDailyReportColumns] = useState([]);
    const [dailySummaryData, setDailySummaryData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).tz(moment.tz.guess()));
    const [dailySummaryColumns, setDailySummaryColumns] = useState([]);
    const [images, setImages] = useState([]);
    const [isImageLoading, setIsImageLoading] = useState(true)

    const safeStructuredClone = (obj) => {
        if (typeof window.structuredClone === 'function') {
            return window.structuredClone(obj);
        }
        return JSON.parse(JSON.stringify(obj));
    };

    const updateTableDate = (newDate) => {
        let clone = safeStructuredClone(dailySummaryColumns)
        clone[new Date(selectedDate).toDateString()] = {
            Header: new Date(newDate).toDateString(),
            columns: [{
                Header: 'Hour',
                accessor: `hour`
            }],
            ordinal: Number.MIN_SAFE_INTEGER
        }
        setDailySummaryColumns(clone)
    }

    const generateTimeFrames = () => {
        let hourToTimeFrame = {};
        let start = moment(new Date().setHours(0, 0, 0, 0));
        let end;
        let i = 0;
        while (i < 24) {
            hourToTimeFrame[i] = `${start.format("hh:mm a")} `;
            end = start.add(59, 'm');
            hourToTimeFrame[i] += `- ${end.format("hh:mm a")}`;
            start = end.add(1, "m");
            i++;
        }
        return hourToTimeFrame;
    }

    const apiDataFormatter = (countsArray) => {
        var resObj = {};
        var result = [];
        for (var i = 0; i < countsArray.length; i++) {
            var obj = {
                "hour": moment(countsArray[i]["startTimestamp"], "YYYY-MM-DDTHH:mm:ss.SSSZZ").tz("America/Halifax").hour(),
                "startTime": moment(countsArray[i]["startTimestamp"], "YYYY-MM-DDTHH:mm:ss.SSSZZ").tz("America/Halifax").format("hh:mm"),
                "endTime": moment(countsArray[i]["endTimestamp"], "YYYY-MM-DDTHH:mm:ss.SSSZZ").tz("America/Halifax").format("hh:mm"),
                "endTimestamp": moment(countsArray[i]["endTimestamp"], "YYYY-MM-DDTHH:mm:ss.SSSZZ"), // will be comapred against UTC dates. leave as-is.
                "upstreamCount": countsArray[i]["upstreamCount"],
                "downstreamCount": countsArray[i]["downstreamCount"],
                "pipelineId": countsArray[i]["pipelineId"]
            };
            result.push(obj);
            resObj.dataReport = result;
        }
        return resObj;
    }

    const getDeviceImage = async (deviceId, time) => {
        var video = await v1TaglessService.getVideos(deviceId, time)
        if (video) {
            var videoId = video[0].id
            var buffer = await v1TaglessService.getImage(videoId)
            var binaryImage = arrayBufferToBase64(buffer)
            return binaryImage
        }
        return null
    }

    const loadImages = async (startDate) => {
        setIsImageLoading(true)
        let allImages = []
        await Promise.all(
            devices.map(async (device) => {
                var utcStartTimestamp = moment.utc(startDate).format("YYYY-MM-DDTHH:mm:ss[Z]")
                const imageBase64 = await getDeviceImage(device.id, utcStartTimestamp)
                allImages.push({ image: imageBase64, deviceName: device.displayName, ordinal: parseInt(device.displayName.replace(/[^\d]/g, '')) })
                return allImages
            })
        )
        setIsImageLoading(false)
        setImages(allImages.sort((a, b) => a.ordinal - b.ordinal))

    }

    const getPipelineMetrics = async (counts) => {
        let pipelines = getUniquePipelines(counts)
        let pipelineMetrics = []
        
        return pipelineMetrics
    }

    const fetchDailyReportTabData = async (startDate, endDate) => {

        let dailyCounts;
        let deviceData;
        let reportPrimaryCols = [{
            Header: new Date(selectedDate).toDateString(),
            columns: [{
                Header: 'Hour',
                accessor: `hour`
            }],
            ordinal: Number.MIN_SAFE_INTEGER
        }]

        let summaryPrimaryCols = []
        let summaryRow = {};
        let observedHours = new Map();
        let hourToTimeMap = generateTimeFrames();

        const siteDevices = await Promise.all(devices.map(async (device) => {
            dailyCounts = await v1TaglessService.getDetections(device.id, {
                startTimestamp: startDate.toISOString(),
                endTimestamp: endDate.toISOString(),
                interval: "1h",
            }).catch(() => {
                setErrorState({
                    hasError: true,
                    message: `unable to load detections data for ${device.displayName}`
                })
            })

            if (dailyCounts) {

                deviceData = {
                    device: device,
                    countData: dailyCounts.counts,
                    validated: !deviceIsValid(device.accurateAsOf, moment(startDate))
                };

                let primarySummaryCol = {
                    Header: device.displayName,
                    columns: [],
                    ordinal: parseInt(device.displayName.replace(/[^\d]/g, ''))
                }

                let primaryReportCol = {
                    Header: device.displayName,
                    columns: [],
                    ordinal: parseInt(device.displayName.replace(/[^\d]/g, ''))
                }

                if (device.type === "CAMERA") {
                    let upstreamCol = {
                        Header: 'Upstream',
                        accessor: `${device.displayName}-upstream`
                    }

                    let downstreamCol = {
                        Header: 'Downstream',
                        accessor: `${device.displayName}-downstream`
                    }


                    primarySummaryCol.columns.push(upstreamCol)
                    primarySummaryCol.columns.push(downstreamCol)
                    primarySummaryCol.columns.push({
                        Header: 'Total',
                        accessor: `${device.displayName}-total`
                    })

                    primaryReportCol.columns.push(upstreamCol)
                    primaryReportCol.columns.push(downstreamCol)

                    let cumulativeData =  deviceData.countData && deviceData.countData.length > 0 ? deviceData.countData.reduce((agg, next) => {
                        if (next["upstreamCount"] != null && next["downstreamCount"] != null) {
                            return {
                                "upstream": agg["upstream"] + next["upstreamCount"],
                                "downstream": agg["downstream"] + next["downstreamCount"],
                                "total": agg["total"] + next["total"]
                            }
                        } else {
                            return {
                                "upstream": agg["upstream"],
                                "downstream": agg["downstream"],
                                "total": agg["total"]
                            }
                        }

                    }, {
                        "upstream": 0,
                        "downstream": 0,
                        "total": 0
                    }) : {
                        "upstream": EMPTY_DATA_STRING,
                        "downstream": EMPTY_DATA_STRING,
                        "total": EMPTY_DATA_STRING
                    }

                    summaryRow[`${device.displayName}-upstream`] = { value: cumulativeData.upstream, shouldGreyOut: deviceData.validated }
                    summaryRow[`${device.displayName}-downstream`] = { value: cumulativeData.downstream, shouldGreyOut: deviceData.validated }
                    summaryRow[`${device.displayName}-total`] = { value: cumulativeData.total, shouldGreyOut: deviceData.validated }
                }

                summaryPrimaryCols.push(primarySummaryCol)
                reportPrimaryCols.push(primaryReportCol)
            }
            return deviceData
        }));

        siteDevices.forEach(async (siteDevice) => {
            if (siteDevice) {
                let formattedData = apiDataFormatter(siteDevice.countData)

                if (formattedData) {
                    let report = formattedData['dataReport']

                    if (report) {
                        report.forEach((val, _) => {
                            let curr = observedHours.get(parseInt(val.hour))
                            if (!curr) {
                                curr = {
                                    [`hour`]: { value: hourToTimeMap[parseInt(val.hour)] }
                                }
                            }

                            const valid = deviceIsValid(siteDevice.device.accurateAsOf, moment(val.endTimestamp))
                            curr[`${siteDevice.device.displayName}-upstream`] = { value: val.upstreamCount !== null ? val.upstreamCount : EMPTY_DATA_STRING, shouldGreyOut: !valid }
                            curr[`${siteDevice.device.displayName}-downstream`] = { value: val.downstreamCount !== null ? val.downstreamCount : EMPTY_DATA_STRING, shouldGreyOut: !valid }

                            observedHours.set(parseInt(val.hour), curr)
                        })
                    }
                }
            }
        })
        setDailyReportColumns(reportPrimaryCols.sort((a, b) => a.ordinal - b.ordinal))
        setDailySummaryColumns(summaryPrimaryCols.sort((a, b) => a.ordinal - b.ordinal))
        setDailySummaryData([summaryRow])

        const data = Array.from(observedHours.values())
        setDailyReportData(data.sort((a, b) => a.ordinal - b.ordinal))
    }

    useEffect(() => {
        let startDate = selectedDate.startOf("day").toDate();
        let endDate = selectedDate.endOf("day").toDate();
        if (!devices) {
            setErrorState({
                hasError: true,
                message: `unable to load detections data for ${site.displayName}`
            })
            return;
        }
        fetchDailyReportTabData(startDate, endDate)
        loadImages(startDate)
    }, [selectedDate, devices])

    return (
        <>
            <DatePicker
                defaultValue={moment().tz('America/Halifax')}
                onChange={(value) => {
                    setSelectedDate(value);
                    updateTableDate(value);
                }}
            />
            <br />


            <Typography variant="h4" component="div" paddingLeft="16px" minHeight="48px" sx={{ color: "#043C4A", padding: 1 }}>Daily Summary</Typography>
            <ImageGrid data={images.length != 0 ? images : devices ? Array.from({ length: devices.length }, (_, i) => ({ ordinal: i, image: "" })) : [{ ordinal: 1, image: "" }]} isLoading={isImageLoading}></ImageGrid>
            <DynamicTable columns={dailySummaryColumns} data={dailySummaryData} />
            <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
                <Typography
                    sx={{ flex: '1 1 100%', color: "#043c4a" }}
                    variant="h4"
                    id="tableTitle"
                    component="div">
                    Hourly Report
                </Typography>
                <CSVLink
                    data={convertToCSV(dailyReportData)}
                    filename={`${site.name}_hourly_report_${selectedDate.format().split("T")[0]}.csv`}
                    target="_blank"
                    onClick={() => {
                        return !(dailyReportData === undefined || dailyReportData.length === 0)
                    }}
                >
                    <Tooltip title='Export Daily Hourly Summary (CSV)'>    
                        <span>
                            <Button
                                sx={{
                                    background: "#4eafb2",
                                    '&:hover': {
                                        backgroundColor: '#043c4a',
                                        boxShadow: 'none',
                                    }
                                }}
                                startIcon={<FileDownloadIcon />}
                                disabled={dailyReportData === undefined || dailyReportData.length === 0}
                                variant="contained"
                            >
                                Export
                            </Button>
                        </span>
                    </Tooltip>
                </CSVLink>
            </Toolbar>
            
            <MinuteReport
                site={site}
                devices={devices}
                selectedDate={selectedDate}
                setErrorState={setErrorState}
                activeTab={activeTab}
            />

        </>
    )
}

export default DailyReport;