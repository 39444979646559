import ReactApexChart from "react-apexcharts";
import './Graph.css'

/**
 * 
 * @param {Object} options 
 * @param {Array | Object} series 
 * @param {integer | string } height - "50%"
 * @param {integer | string} width  - "97%"
 * @returns A JSX component that renders a graph using the specified props
 */
const Graph = ({options, series, height = 400, width = "97%"}) => {
  return (
    <ReactApexChart
      options={{
        colors: ["#48A3A1", "#8BD4DF", "#043C4A", "#BFE3D6"], ...options
      }}
      series={series}
      height={height}
      width={width}
    />
  );
}

export default Graph;